<template>
    <b-modal v-model="show" id="approve-billing-report" ref="approve-billing-report" size="xl" @show="onReset"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <template #modal-header>
            <div class="modal-title">
                <i class="fa fa-check"></i>
                <span>&nbsp;&nbsp; Confirm Approval for <span class="numFont">{{ billingReportNo }}</span></span>
            </div>
        </template>

        <b-container fluid>
            <b-row no-gutters>
                <b-col md="8" sm="6">
                    <b>PRIMARY INFORMATION</b>
                </b-col>
            </b-row>
            <b-row class="mb-4">
                <!-- Account Details -->
                <b-col lg="4" md="12" sm="12" class="mt-4">
                    <AccountDetails :billingReport="billingReport" :clientAccount="clientAccount" />
                </b-col>

                <!-- Charge Type Details -->
                <b-col lg="4" md="12" sm="12" class="mt-4">
                    <ChargeTypeDetails :billingReport="billingReport" />
                </b-col>

                <!-- Billing Details -->
                <b-col lg="4" md="12" sm="12" class="mt-4">
                    <BillingDetails :billingReport="billingReport" :hasEdit="false" :hasPreviousBillings="false" />
                </b-col>
            </b-row>


            <!-- Billing Summary -->
            <hr />
            <b-row class="mt-4">
                <b-col lg="6" md="12" sm="12">
                    <b>BILLING SUMMARY</b>
                    <BillingComputationSummary :billingReport="billingReport" />
                </b-col>
                <b-col lg="6" md="12" sm="12" class="mt-4">
                    <span class="info" v-html="getConfirmApproveTxt()"></span>
                    <b-form-group class="mt-2" description="Please indicate the reason of approval/rejection here"
                        label-for="notes">
                        <b-form-textarea name="Reason" type="text" v-model="billingReport.remarks" maxlength="200"
                            v-validate="getValidationParam(true, remarksRegex)" :rows="3" placeholder="" />
                        <span v-show="errors.has('Reason')" class="help-block">
                            {{ errors.first('Reason') }}
                        </span>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>

        <template #modal-footer>
            <div class="w-100">
                <span class="float-right">
                    <b-button variant="secondary" @click="show = false" class="footer-button mr-2">
                        Close
                    </b-button>
                    <b-button variant="danger" @click="rejectReport" class="footer-button mr-2">
                        Reject
                    </b-button>
                    <b-button variant="success" @click="approveReport" class="footer-button">
                        Approve
                    </b-button>
                </span>
            </div>
        </template>
    </b-modal>
</template>

<script>
// Components
import AccountDetails from './primaryDetails/AccountDetails.vue';
import ChargeTypeDetails from './primaryDetails/ChargeTypeDetails.vue';
import BillingDetails from './primaryDetails/BillingDetails.vue';
import BillingComputationSummary from './BillingComputationSummary.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// API & DAO
import billingReportApi from '@/api/billingReportApi';
import billingReportDAO from '@/database/billingReports';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'confirm-billing-report-approval',
    components: {
        AccountDetails,
        ChargeTypeDetails,
        BillingDetails,
        BillingComputationSummary,
        Loading
    },
    props: {
        allClientAccountsObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: false,
            billingReport: {},
            clientAccount: {},

            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '-';
        },
        remarksRegex() {
            return config.remarksRegex;
        },
    },
    mounted() {
        EventBus.$on("onApproveBillingReport", (billingReport) => {
            this.$bvModal.show('approve-billing-report');
            this.onReset(billingReport);
        });
    },
    methods: {
        getConfirmApproveTxt() {
            return '<i class="fa fa-info-circle" aria-hidden="true"></i> By clicking <strong>Approve</strong> or <strong>Reject</strong>, you won\'t be able to make any further edits on this billing report.';
        },
        getValidationParam(isRequired, regex) {
            return {
                required: isRequired,
                regex: regex,
            };
        },

        async rejectReport() {
            this.billingReport.status = 'Rejected';
            this.billingReport.dateUpdated = DateUtil.getCurrentTimestamp();
            this.billingReport.updatedBy = this.loggedUser.id;
            this.billingReport.dateRejected = DateUtil.getCurrentTimestamp();
            this.billingReport.rejectedBy = this.loggedUser.id;

            await this.handleOk();

        },
        async approveReport() {
            let isValid = await this.validateApproval();
            if (isValid) {
                this.billingReport.status = 'Approved';
                this.billingReport.dateUpdated = DateUtil.getCurrentTimestamp();
                this.billingReport.updatedBy = this.loggedUser.id;
                this.billingReport.dateApproved = DateUtil.getCurrentTimestamp();
                this.billingReport.approvedBy = this.loggedUser.id;

                await this.handleOk();
            }
        },
        async validateApproval() {
            let clientAccountId = this.billingReport.clientAccountId;
            let clientAccountNo = this.billingReport.clientAccountNo;
            let chargeType = this.billingReport.chargeType;
            let billingNumber = this.billingReport.billingNumber;

            let prevBillingReportsObj = await billingReportDAO.getPreviousBillingReports(clientAccountId, chargeType, ['Approved'], '', 1);
            let prevBillingReportsArr = Object.values(prevBillingReportsObj);
            let prevBillingReportObj = prevBillingReportsArr[0];
            if (prevBillingReportObj && billingNumber === prevBillingReportObj.billingNumber) {
                this.$toaster.warning('There is already an approved billing for ' + clientAccountNo + ' at ' + billingNumber
                    + '. Please see ' + prevBillingReportObj.billingReportNo + ' for you reference.');
                return false;
            }
            return true;
        },

        async handleOk() {
            try {
                // show loading indicator
                this.isLoading = true;

                let isValid = await this.$validator.validateAll();
                if (!isValid) {
                    this.$toaster.warning('Please address the field/s with invalid input');
                    this.isLoading = false;
                    return;
                }

                let reportObj = BillingReportUtil.cleanupFields(this.billingReport);

                let { data } = await billingReportApi.saveBillingReport(
                    reportObj,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    if (this.billingReport.status === 'Approved') {
                        this.$toaster.success(`Billing Report "${this.billingReportNo}" was approved successfully.`);
                    } else {
                        this.$toaster.success(`Billing Report "${this.billingReportNo}" was rejected successfully.`);
                    }

                    this.$emit("onConfirm", this.billingReport);
                    this.$bvModal.hide('approve-billing-report');
                } else {
                    this.$toaster.error(`Error confirming approval for billing report "${this.billingReportNo}". Please try again.`);
                }

            } catch (error) {
                this.$toaster.error(`Error confirming approval for billing report "${this.billingReportNo}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },
        onReset(billingReport) {
            if (billingReport && !_.isEmpty(billingReport)) {
                this.billingReport = { ...billingReport };
                this.clientAccount = this.allClientAccountsObj[billingReport.clientAccountId];
            }
        },
    },
    beforeDestroy() {
        EventBus.$off('onApproveBillingReport');
    },
}
</script>

<style scoped>
.primary-details-section {
    margin-top: 20px;
}
</style>